<template>
  <img alt="Vue logo" src="./assets/codeokplease_logo.jpg" class="center" />
</template>

<script>
// import HelloWorld from "./components/HelloWorld.vue";

export default {
  name: "App",
  // components: {
  //   HelloWorld,
  // },
};
</script>

<style>
#app {
  background-color: #ffc413;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  text-align: center;
  background-color: #ffc413;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
</style>
